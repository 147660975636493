@media (max-width: 992px) {
  body > header, body.home:not(.scrolled) > header {
  }
  body > header .container .logo {
    flex-grow: 0;
    height: auto;
    align-items: center;
  }
  body.home:not(.scrolled) > header .container .logo {opacity: 1;}
  body > header .container .logo img {height: 75px; margin: 10px 0;}
  body > header .container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    transform: translateX(-100vw);
    background: #000;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: transform 0.3s;
  }
  body > header .container.open {
    transform: translateX(0);
  }

  body > header .container ul.menu {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding-top: 2em;
  }
  body > header .container ul.menu > li > ul,
  body > header .container ul.menu > li > form {position: static;width: auto; margin: auto; max-width: 300px;padding: 0;background: transparent; padding-bottom: 15px;}
  body > header .container ul.menu > li:focus-within {
    margin-bottom: 0;
    background: #333;
  }
  body > header .container ul.menu > li:focus-within > ul,
  body > header .container ul.menu > li:focus-within > form {height: auto;margin: auto; }

  body > header .container ul.menu > li > ul li {
    background:transparent;
  }

  #mobilemenu-open {
    display: block;
    position: absolute;
    top:0;right:0;
    z-index: 99;
    width: 60px;
    height: 60px;
    background: #000 url('../img/burger.svg') center center no-repeat;
    background-size: 30px 30px;
    color: transparent;
    font-size: 6px;
    text-indent: -100px;
  }

  .homejumbo {
    height: auto;
    min-height: 40vh;
  }

  .homejumbo .container {
    flex-basis: 0;
    padding-bottom: 0;
  }
  .homejumbo img {
    height: auto;
    width: 80vw;
    display: block;
    margin: auto;
  }

}