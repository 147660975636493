/*
 * Table styles
 */
/*
 * Control feature layout
 */
table.dataTable {
  th.dt-left {
    text-align: left;
  }
  td.dt-left {
    text-align: left;
  }
  th.dt-center {
    text-align: center;
  }
  td.dt-center {
    text-align: center;
  }
  td.dataTables_empty {
    text-align: center;
  }
  th.dt-right {
    text-align: right;
  }
  td.dt-right {
    text-align: right;
  }
  th.dt-justify {
    text-align: justify;
  }
  td.dt-justify {
    text-align: justify;
  }
  th.dt-nowrap {
    white-space: nowrap;
  }
  td.dt-nowrap {
    white-space: nowrap;
  }
  thead {
    th.dt-head-left {
      text-align: left;
    }
    td.dt-head-left {
      text-align: left;
    }
    th.dt-head-center {
      text-align: center;
    }
    td.dt-head-center {
      text-align: center;
    }
    th.dt-head-right {
      text-align: right;
    }
    td.dt-head-right {
      text-align: right;
    }
    th.dt-head-justify {
      text-align: justify;
    }
    td.dt-head-justify {
      text-align: justify;
    }
    th.dt-head-nowrap {
      white-space: nowrap;
    }
    td.dt-head-nowrap {
      white-space: nowrap;
    }
    th {
      font-weight: bold;
      padding: 10px 18px;
      border-bottom: 1px solid #111;
      &:active {
        outline: none;
      }
    }
    td {
      padding: 10px 18px;
      border-bottom: 1px solid #111;
      &:active {
        outline: none;
      }
    }
    .sorting {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../img/datatables/sort_both.png");
    }
    .sorting_asc {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../img/datatables/sort_asc.png") !important;
    }
    .sorting_desc {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../img/datatables/sort_desc.png") !important;
    }
    .sorting_asc_disabled {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../img/datatables/sort_asc_disabled.png");
    }
    .sorting_desc_disabled {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../img/datatables/sort_desc_disabled.png");
    }
  }
  tfoot {
    th.dt-head-left {
      text-align: left;
    }
    td.dt-head-left {
      text-align: left;
    }
    th.dt-head-center {
      text-align: center;
    }
    td.dt-head-center {
      text-align: center;
    }
    th.dt-head-right {
      text-align: right;
    }
    td.dt-head-right {
      text-align: right;
    }
    th.dt-head-justify {
      text-align: justify;
    }
    td.dt-head-justify {
      text-align: justify;
    }
    th.dt-head-nowrap {
      white-space: nowrap;
    }
    td.dt-head-nowrap {
      white-space: nowrap;
    }
    th {
      font-weight: bold;
      padding: 10px 18px 6px 18px;
      border-top: 1px solid #111;
    }
    td {
      padding: 10px 18px 6px 18px;
      border-top: 1px solid #111;
    }
  }
  tbody {
    th.dt-body-left {
      text-align: left;
    }
    td.dt-body-left {
      text-align: left;
    }
    th.dt-body-center {
      text-align: center;
    }
    td.dt-body-center {
      text-align: center;
    }
    th.dt-body-right {
      text-align: right;
    }
    td.dt-body-right {
      text-align: right;
    }
    th.dt-body-justify {
      text-align: justify;
    }
    td.dt-body-justify {
      text-align: justify;
    }
    th.dt-body-nowrap {
      white-space: nowrap;
    }
    td.dt-body-nowrap {
      white-space: nowrap;
    }
    tr {
      background-color: #ffffff;
    }
    tr.selected {
      background-color: #B0BED9;
    }
    th {
      padding: 8px 10px;
    }
    td {
      padding: 8px 10px;
    }
  }
  td.dt-control {
    text-align: center;
    cursor: pointer;
    &:before {
      height: 1em;
      width: 1em;
      margin-top: -9px;
      display: inline-block;
      color: white;
      border: 0.15em solid white;
      border-radius: 1em;
      box-shadow: 0 0 0.2em #444;
      box-sizing: content-box;
      text-align: center;
      text-indent: 0 !important;
      font-family: "Courier New", Courier, monospace;
      line-height: 1em;
      content: "+";
      background-color: #31b131;
    }
  }
  tr.dt-hasChild {
    td.dt-control {
      &:before {
        content: "-";
        background-color: #d33333;
      }
    }
  }
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  th {
    box-sizing: content-box;
  }
  td {
    box-sizing: content-box;
  }
}
table.dataTable.row-border {
  tbody {
    th {
      border-top: 1px solid #ddd;
    }
    td {
      border-top: 1px solid #ddd;
    }
    tr {
      &:first-child {
        th {
          border-top: none;
        }
        td {
          border-top: none;
        }
      }
    }
  }
}
table.dataTable.display {
  tbody {
    th {
      border-top: 1px solid #ddd;
    }
    td {
      border-top: 1px solid #ddd;
    }
    tr {
      &:first-child {
        th {
          border-top: none;
        }
        td {
          border-top: none;
        }
      }
      &:hover {
        background-color: #f6f6f6;
        >.sorting_1 {
          background-color: #eaeaea;
        }
        >.sorting_2 {
          background-color: #ececec;
        }
        >.sorting_3 {
          background-color: #efefef;
        }
      }
      &:hover.selected {
        background-color: #aab7d1;
        >.sorting_1 {
          background-color: #a2aec7;
        }
        >.sorting_2 {
          background-color: #a3b0c9;
        }
        >.sorting_3 {
          background-color: #a5b2cb;
        }
      }
      >.sorting_1 {
        background-color: #fafafa;
      }
      >.sorting_2 {
        background-color: #fafafa;
      }
      >.sorting_3 {
        background-color: #fafafa;
      }
    }
    tr.odd {
      background-color: #f9f9f9;
      >.sorting_1 {
        background-color: #f1f1f1;
      }
      >.sorting_2 {
        background-color: #f3f3f3;
      }
      >.sorting_3 {
        background-color: whitesmoke;
      }
    }
    tr.odd.selected {
      background-color: #acbad4;
      >.sorting_1 {
        background-color: #a6b4cd;
      }
      >.sorting_2 {
        background-color: #a8b5cf;
      }
      >.sorting_3 {
        background-color: #a9b7d1;
      }
    }
    tr.selected {
      >.sorting_1 {
        background-color: #acbad5;
      }
      >.sorting_2 {
        background-color: #acbad5;
      }
      >.sorting_3 {
        background-color: #acbad5;
      }
    }
    tr.even {
      >.sorting_1 {
        background-color: #fafafa;
      }
      >.sorting_2 {
        background-color: #fcfcfc;
      }
      >.sorting_3 {
        background-color: #fefefe;
      }
    }
    tr.even.selected {
      >.sorting_1 {
        background-color: #acbad5;
      }
      >.sorting_2 {
        background-color: #aebcd6;
      }
      >.sorting_3 {
        background-color: #afbdd8;
      }
    }
  }
}
table.dataTable.cell-border {
  tbody {
    th {
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    td {
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    tr {
      th {
        &:first-child {
          border-left: 1px solid #ddd;
        }
      }
      td {
        &:first-child {
          border-left: 1px solid #ddd;
        }
      }
      &:first-child {
        th {
          border-top: none;
        }
        td {
          border-top: none;
        }
      }
    }
  }
}
table.dataTable.stripe {
  tbody {
    tr.odd {
      background-color: #f9f9f9;
    }
    tr.odd.selected {
      background-color: #acbad4;
    }
  }
}
table.dataTable.hover {
  tbody {
    tr {
      &:hover {
        background-color: #f6f6f6;
      }
      &:hover.selected {
        background-color: #aab7d1;
      }
    }
  }
}
table.dataTable.order-column {
  tbody {
    tr {
      >.sorting_1 {
        background-color: #fafafa;
      }
      >.sorting_2 {
        background-color: #fafafa;
      }
      >.sorting_3 {
        background-color: #fafafa;
      }
    }
    tr.selected {
      >.sorting_1 {
        background-color: #acbad5;
      }
      >.sorting_2 {
        background-color: #acbad5;
      }
      >.sorting_3 {
        background-color: #acbad5;
      }
    }
  }
}
table.dataTable.order-column.stripe {
  tbody {
    tr.odd {
      >.sorting_1 {
        background-color: #f1f1f1;
      }
      >.sorting_2 {
        background-color: #f3f3f3;
      }
      >.sorting_3 {
        background-color: whitesmoke;
      }
    }
    tr.odd.selected {
      >.sorting_1 {
        background-color: #a6b4cd;
      }
      >.sorting_2 {
        background-color: #a8b5cf;
      }
      >.sorting_3 {
        background-color: #a9b7d1;
      }
    }
    tr.even {
      >.sorting_1 {
        background-color: #fafafa;
      }
      >.sorting_2 {
        background-color: #fcfcfc;
      }
      >.sorting_3 {
        background-color: #fefefe;
      }
    }
    tr.even.selected {
      >.sorting_1 {
        background-color: #acbad5;
      }
      >.sorting_2 {
        background-color: #aebcd6;
      }
      >.sorting_3 {
        background-color: #afbdd8;
      }
    }
  }
}
table.dataTable.order-column.hover {
  tbody {
    tr {
      &:hover {
        >.sorting_1 {
          background-color: #eaeaea;
        }
        >.sorting_2 {
          background-color: #ececec;
        }
        >.sorting_3 {
          background-color: #efefef;
        }
      }
      &:hover.selected {
        >.sorting_1 {
          background-color: #a2aec7;
        }
        >.sorting_2 {
          background-color: #a3b0c9;
        }
        >.sorting_3 {
          background-color: #a5b2cb;
        }
      }
    }
  }
}
table.dataTable.no-footer {
  border-bottom: 1px solid #111;
}
table.dataTable.nowrap {
  th {
    white-space: nowrap;
  }
  td {
    white-space: nowrap;
  }
}
table.dataTable.compact {
  thead {
    th {
      padding: 4px 17px;
    }
    td {
      padding: 4px 17px;
    }
  }
  tfoot {
    th {
      padding: 4px;
    }
    td {
      padding: 4px;
    }
  }
  tbody {
    th {
      padding: 4px;
    }
    td {
      padding: 4px;
    }
  }
}
.dataTables_wrapper {
  position: relative;
  clear: both;
  .dataTables_length {
    float: left;
    color: #333;
    select {
      border: 1px solid #aaa;
      border-radius: 3px;
      padding: 5px;
      background-color: transparent;
      padding: 4px;
    }
  }
  .dataTables_filter {
    float: right;
    text-align: right;
    color: #333;
    input {
      border: 1px solid #aaa;
      border-radius: 3px;
      padding: 5px;
      background-color: transparent;
      margin-left: 3px;
    }
  }

  .dataTables_length, .dataTables_filter {
    padding-bottom: 0.5em;
  }
  .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    color: #333;
  }
  .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 1em;
    color: #333;
    .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px;
      &:hover {
        color: white !important;
        //border: 1px solid #111;
        background-color: $primary;
        //background: linear-gradient(to bottom, #585858 0%, #111 100%);
      }
      &:active {
        outline: none;
        background-color: #2b2b2b;
        //background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
        background: $primary;
        //box-shadow: inset 0 0 3px #111;
      }
    }
    .paginate_button.current {
      //color: #333 !important;
      color: #FFF !important;
      //border: 1px solid #979797;
      background-color: $primary;
      //background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
      &:hover {
        //color: #333 !important;
        color: #FFF !important;
        //border: 1px solid #979797;
        background-color: darken($primary, 10%);
        //background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
      }
    }
    .paginate_button.disabled {
      cursor: default;
      color: #666 !important;
      border: 1px solid transparent;
      background: transparent;
      box-shadow: none;
      &:hover {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
      }
      &:active {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
      }
    }
    .ellipsis {
      padding: 0 1em;
    }
  }
  .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    color: #333;
  }
  .dataTables_scroll {
    clear: both;
    div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch;
      >table {
        >thead {
          >tr {
            >th {
              vertical-align: middle;
              >div.dataTables_sizing {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
                padding: 0 !important;
              }
            }
            >td {
              vertical-align: middle;
              >div.dataTables_sizing {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          }
        }
        >tbody {
          >tr {
            >th {
              vertical-align: middle;
              >div.dataTables_sizing {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
                padding: 0 !important;
              }
            }
            >td {
              vertical-align: middle;
              >div.dataTables_sizing {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}
.dataTables_wrapper.no-footer {
  .dataTables_scrollBody {
    border-bottom: 1px solid #111;
  }
  div.dataTables_scrollHead {
    table.dataTable {
      border-bottom: none;
    }
  }
  div.dataTables_scrollBody {
    >table {
      border-bottom: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .dataTables_wrapper {
    .dataTables_info {
      float: none;
      text-align: center;
    }
    .dataTables_paginate {
      float: none;
      text-align: center;
      margin-top: 0.5em;
    }
  }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper {
    .dataTables_length {
      float: none;
      text-align: center;
    }
    .dataTables_filter {
      float: none;
      text-align: center;
      margin-top: 0.5em;
    }
  }
}
