.homejumbo{
  background: #333 url('../img/trp_b.jpg') center bottom no-repeat;
  background-size: cover;
  height: 40vh;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
}
.homejumbo.a {background-image: url('../img/perc_b.jpg');}
.homejumbo .container {
  flex-basis: 50%;
  flex-grow: 0;
}
.homejumbo h2 {
  border: none;
  margin-bottom: .5rem;
}

.homejumbo .row > div.text-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.homejumbo img {
  height: 20vw;
  width: auto;
  max-height: 167px;
  max-width: 414px;
}