
body.dashboard {
  & > header {
    background: #600;
    a {
      //color: #F44;
    }
    .container {
      justify-content: flex-start;
      ul {
        &.menu {
          flex-grow: 0;
          gap: 2rem;
          li.main-item {
            background: #000;
            a {
              padding: 0 2em;
            }
          }
        }
      }
      .logo {
        background: #000;
        flex-grow: 0;
        a {
          padding-left: 2em;
        }
      }
    }
  }

  .main {
    & > h1 {
      padding: 1em 0 1rem;
    }
    p.actions {
      padding-bottom: 1rem;
    }
  }

  .datetimefield {
    display: flex;
    gap: 8px;
    max-width: 320px;
  }

}