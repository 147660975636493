.event-list {
  .event {
    background: #EEE;
    &:not(:first-child) {
      margin-top: 4px;
    }
    & > * {
      min-height: 4rem;
      padding: {
        top: 1em;
        bottom: 1em;
      }
    }
    /*
    height: 7em;
    overflow-y: hidden;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2em;
      background: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgb(238, 238, 238) 90%);
      content: " ";
    }
    &.open {
      height: auto;
      &::after {
        display: none;
      }
    }
    */
  }
  .date {
    font-family: "Playfair Display", serif;
    text-align: center;
    .daymonth {
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
    }
  }
  .details {
    p {
      margin-bottom: 0;
    }
  }
}