body > footer {
  background: #000;
  color: #F8F8F8;
  padding: 4em 0;
  h2, h3, a {
    color: #c1974f;
    border: none;
  }
  a:hover {
    color: #FFF;
  }
}