body {
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 18px;
  background: #F8F8F8;
  color: #333;
  padding-top: 55px;
}
body.home {
  padding-top: 0;
}
strong {
  font-weight: 900;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:link, .btn-primary:visited {
  color: #FFF;
}
.gold {
  color: #c1974f;
}
.back-gold {
  background: #c1974f;
}
h1, h2 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}
h2 {
  border-bottom: 1px solid #333;
  margin-bottom: 1.5rem;
  padding-bottom: 0.1em;
}
h3 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  margin-bottom: 1rem;
}
h3::first-letter {
  text-transform: uppercase;
}
a[target="_blank"]:not(.imglink) {
  &:before {
    content: "↪";
    padding-right: 4px;
    font-size: 0.7em;
    display: inline-block;
  }
}
a.unstyled {
  color: inherit;
  text-decoration: none;
}
.infometa {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

section.pagetitle {
  background: $primary;
  color: #FFF;
  @extend .py-4;
}

.card {
}
audio, video {
  max-width: 100%;
}
.datetime {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  font-size: 0.8em;
}