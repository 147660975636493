body {
  & > header {
    background: #000;
    color: $primary;
    position: fixed;
    z-index: 4;
    top: 0;
    left:0;
    right: 0;
    font-family: 'Playfair Display', serif;
    transition: all 0.3s;

    a {
      color: $primary;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        color:#FFF;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;

      .logo {
        flex-grow: 1;
        height: 55px;
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        img {
          height: 40px;
          margin-top: -10px;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
          display: inline-block;
          line-height: 53px;
          height: 55px;
        }
        &.menu {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;

          & > li {
            position: relative;
            transition: margin 0.3s;
            & > ul {
              position: absolute;
              height: 0;
              overflow: hidden;
              opacity:0;
              transition: all 0.3s;
              li {
                background:#000;
              }
            }
          }
        }
        ul {
          a {
            display: block;
          }
        }
        li {
          &.hasmenu {
            & > a {
              padding-right: 30px;
              background: transparent url('../img/down.png') right center no-repeat;
            }
          }
        }
      }
    }
  }
}
body > header .container ul.menu > li > form {
  font-family: Lato, sans-serif;
  position: absolute;
  background:#000;
  height: 0;
  overflow: hidden;
  opacity:0;
  transition: all 0.3s;
  right: 0;
  width: 300px;
}
body > header .container ul.menu > li > form div{
  padding: 0 15px;
}

body > header .container ul.menu > li > ul {
  margin: 0 -15px;
}
body > header .container ul.menu > li > ul li {
  /*background: transparent;*/
  padding: 0 15px;
}
body > header .container ul.menu > li.hasmenu:focus-within > ul {
  transform: translateY(0);
  opacity:1;
  height: 200px;
}
body > header .container ul.menu > li.hasmenu:focus-within {
  margin-bottom: 245px;
}
body > header .container ul.menu > li.hasmenu:focus-within > form {
  transform: translateY(0);
  opacity:1;
  height: 245px;
}
#mobilemenu-open {display: none;}





body.home:not(.scrolled) {
  & > header {
    background: transparent;
    .container {
      .logo {
        opacity: 0;
      }
    }
  }
}